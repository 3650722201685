import { makeStyles, Tab, Tabs } from '@material-ui/core'
import React, { ChangeEvent, FC, useState } from 'react'

import ChurchIcon from '@/assets/svg/church.svg'
import DollarIcon from '@/assets/svg/dollar.svg'
import PaymentIcon from '@/assets/svg/payment.svg'
import { PageContainer } from '@/components/common/PageContainer'
import { TabPanel } from '@/components/common/TabPanel'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      letterSpacing: '0.046875em',
    },
    title: {
      color: palette.primary.main,
      fontSize: '2rem',
      fontWeight: typography.fontWeightBold,
      textTransform: 'uppercase',
      textAlign: 'center',
      [breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
      [breakpoints.down('xs')]: {
        fontSize: '1.125rem',
      },
    },
    tabs: {
      margin: spacing(6, 0),
      borderBottom: `1px solid ${palette.divider}`,
      [breakpoints.down('sm')]: {
        margin: spacing(4, 0),
      },
    },
    tab: {
      fontSize: '2rem',
      lineHeight: 1.25,
      textTransform: 'none',
      [breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
      [breakpoints.down('xs')]: {
        paddingRight: spacing(1),
        paddingLeft: spacing(1),
        fontSize: '1rem',
      },
    },
    tabWrapper: {
      flexDirection: 'row',
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      '& > :first-child': {
        flex: 'none',
        marginRight: spacing(1),
        height: '1em',
        width: '1em',
        [breakpoints.down('xs')]: {
          marginRight: 0,
          marginBottom: spacing(0.75),
        },
      },
    },
    tabContent: {
      padding: spacing(2, 6),
      fontSize: '1.5rem',
      lineHeight: 1.333333,
      [breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
      [breakpoints.down('xs')]: {
        padding: spacing(2),
        fontSize: '0.875rem',
      },
    },
    highlighted: {
      color: palette.primary.main,
    },
    link: {
      color: 'inherit',
    },
    footnote: {
      marginTop: spacing(10),
      fontSize: '1.5rem',
      [breakpoints.down('sm')]: {
        marginTop: spacing(6),
        fontSize: '1.125rem',
      },
      [breakpoints.down('xs')]: {
        marginTop: spacing(4),
        fontSize: '0.875rem',
      },
    },
    value: {
      [breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  })
)

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const TabsSection: FC = () => {
  const classes = useStyles()
  const [value, setValue] = useState(1)

  const handleChange = (_event: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <PageContainer className={classes.root}>
      <div className={classes.title}>How to Give</div>
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        centered
        aria-label="Giving options"
      >
        <Tab
          classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
          label="During Worship"
          icon={<ChurchIcon />}
          {...a11yProps(0)}
        />
        <Tab
          classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
          label="Direct Deposit"
          icon={<PaymentIcon />}
          {...a11yProps(1)}
        />
        <Tab
          classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
          label="US Dollar Contributions"
          icon={<DollarIcon />}
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <p className={classes.tabContent}>
          Present your tithes and offerings during worship.
          <br />
          <br />
          To receive a German tax statement, place your money in an envelope,
          which is available from our ushers. Write your name and address
          clearly on the outside and put it on the plate.
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <p className={classes.tabContent}>
          Make a direct deposit to the church account using SEPA. See
          information below.
          <br />
          <br />
          <br />
          You will receive a German tax statement if you also indicate your
          complete name and address. If you are contributing to one of our
          church approved designated funds (i.e. missions, benevolence, etc.),
          please note that in the{' '}
          <em>
            <strong>“Verwendungszweck”</strong>
          </em>
          , otherwise the contribution will be directed to our general fund.
          <br />
          <br />
          <span className={classes.highlighted}>
            Account holder:{' '}
            <strong className={classes.value}>
              International Baptist Church Berlin
            </strong>
            <br />
            Account number (IBAN):{' '}
            <strong className={classes.value}>
              DE85 5009 2100 0000 7443 01
            </strong>
            <br />
            Bank number (BIC):{' '}
            <strong className={classes.value}>GENODE51BH2</strong>
          </span>
          <br />
          <br />
          The bank’s name is{' '}
          <strong className={classes.highlighted}>
            “Spar und Kreditbank EFGeG”
          </strong>
          .
        </p>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <p className={classes.tabContent}>
          Write your US dollar check using the information below and present it
          during worship, or mail it directly to International Baptist Church
          Ministries - see{' '}
          <a
            className={classes.link}
            href="https://www.ibcmworld.com"
            target="_blank"
            rel="noreferrer"
          >
            www.ibcmworld.com
          </a>
          . You will receive a US tax statement directly from IBCM.
          <br />
          <br />
          <br />
          <span className={classes.highlighted}>
            To: <strong>International Baptist Church Ministries, Inc.</strong>
            <br />
            For: <strong>IBC Berlin (important!)</strong>
          </span>
        </p>
      </TabPanel>
      <div className={classes.footnote}>
        For more information, contact Finance Team.
      </div>
    </PageContainer>
  )
}
