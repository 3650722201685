import React, { FC } from 'react'

type TabPanelProps = {
  index: number
  value: unknown
}

export const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...rest
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  )
}
