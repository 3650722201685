import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { PageCover } from '@/components/common/PageCover'
import { QuoteSection } from '@/components/give/QuoteSection'
import { TabsSection } from '@/components/give/TabsSection'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "give" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

const GivePage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)
  return (
    <>
      <SEO title="Give" />
      <PageCover image={image} title="Give" />
      <QuoteSection />
      <TabsSection />
    </>
  )
}

export default GivePage
